import { AnimateSharedLayout } from "framer-motion";
import React, { useEffect, useState } from "react";
import { ChapterEntity } from "../../generated/graphql";
import { RefLibraryItem } from "./ref-library-item/ref-library-item";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { SearchIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { InlineAssistant } from "./ref-library-item/inline-assistant";
import { TypeText } from "../../components/type-text/type-text";

const IS_ROLLEASE = window.location.host.indexOf("rollease") > -1;
const IS_DEVELOPMENT = window.location.host.indexOf("development") > -1;
const IS_PRODUCT = window.location.host.indexOf("product") > -1;
const IS_WINDOW = window.location.host.indexOf("window") > -1;
const IS_LOCALHOST = window.location.host.indexOf("localhost") > -1;
// const IS_ROLLEASE = true;

const SHOULD_SHOW_MS_ASSIST = IS_ROLLEASE || IS_DEVELOPMENT || IS_PRODUCT || IS_LOCALHOST || IS_WINDOW;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface Props {
  data: ChapterEntity[];
  firstInitiativename?: string;
  refdocCount: {[k: string]: number};
  assignedInitiativeIds: number[];
  refetchInitiatives: () => Promise<void>;
}

export const ReferenceLibrary = (props: Props) => {
  const [searchText, setSearchText] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const [showDocumentList, setShowDocumentList] = useState((SHOULD_SHOW_MS_ASSIST) ? false : true);

  const [showInlineAssistant, setShowInlineAssistant] = useState(false);
  
  let { data, firstInitiativename } = props;

  data = JSON.parse(JSON.stringify(data));

  data.sort((a, b) => {
    if(a.chapter_name === firstInitiativename) {
      return -1;
    }

    if(b.chapter_name === firstInitiativename) {
      return 1;
    }

    const totalA = a.refdocs.map(doc => props.refdocCount[doc.id] || 0).reduce(function (x, y) {
      return x + y;
    }, 0);

    const totalB = b.refdocs.map(doc => props.refdocCount[doc.id] || 0).reduce(function (x, y) {
      return x + y;
    }, 0);

    if(totalA > totalB) {
      return -1;
    } else {
      return 1;
    }
  });


  if (searchText) {
    data = data.map((chapter) => {
      return {
        ...chapter,
        refdocs: chapter.refdocs.filter((doc) => {
          return (
            doc.document_name.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1
          );
        }),
      };
    });
  }

  return (
    <div
      className="p-4"
      style={
        {
          // background: "#f6f6f6",
          // padding: "5px 15px",
          // borderRadius: 5,
        }
      }
    >
      {/*{showDoc &&*/}
      {/*<div className="Example">*/}
      {/*    <header>*/}
      {/*        <h1>react-pdf sample page</h1>*/}
      {/*    </header>*/}
      {/*    <div className="Example__container">*/}
      {/*        <div className="Example__container__load">*/}
      {/*            <label htmlFor="file">Load from file:</label>*/}
      {/*            {' '}*/}
      {/*        </div>*/}
      {/*        <div className="Example__container__document">*/}
      {/*            <Document*/}
      {/*                file="https://d1c8m9iw60oq0a.cloudfront.net/c475786c-44d7-42da-ab52-8af36bf9f94b.pdf"*/}
      {/*                onLoadSuccess={onDocumentLoadSuccess}*/}
      {/*            >*/}
      {/*                /!*{*!/*/}
      {/*                /!*    Array.from(*!/*/}
      {/*                /!*        new Array(numPages),*!/*/}
      {/*                /!*        (el, index) => (*!/*/}
      {/*                /!*            <Page*!/*/}
      {/*                /!*                key={`page_${index + 1}`}*!/*/}
      {/*                /!*                pageNumber={index + 1}*!/*/}
      {/*                /!*            />*!/*/}
      {/*                /!*        ),*!/*/}
      {/*                /!*    )*!/*/}
      {/*                /!*}*!/*/}
      {/*                <Page pageNumber={pageNumber} />*/}
      {/*            </Document>*/}
      {/*            <p>Page {pageNumber} of {numPages}</p>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>}*/}

      <div className="pb-4 flex justify-between items-center">
        {!(SHOULD_SHOW_MS_ASSIST) && <div className="text-xl font-bold flex-1 items-center">
          Reference Library
        </div>}
      
        {(SHOULD_SHOW_MS_ASSIST) && <>
          <div className="text-xl font-bold flex-1 items-center text-left cursor-pointer flex" onClick={() => setShowInlineAssistant(true)}>
            MindStrength&nbsp;
            <TypeText stylingClass="text-xl" delay={500} fontSize={20} startDelay={1000} onComplete={() => {
              setShowDocumentList(true);
            }}>
              <span className="text-blue-500">
                Assist
                <span className="blink_me">_</span>
              </span>
            </TypeText>
          </div>

          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Beta
          </span>
        </>}

        {/* <SearchIcon
          onClick={() =>
            showSearch ? setShowSearch(false) : setShowSearch(true)
          }
          className="h-5 w-5 text-gray-700"
        /> */}
      </div>

      {showInlineAssistant && <InlineAssistant refetchInitiatives={props.refetchInitiatives} toggle={setShowInlineAssistant} assignedInitiativeIds={props.assignedInitiativeIds} />}

      {showSearch && (
        <div>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            {!!searchText && (
              <div
                className="absolute top-3 p-1 flex items-center justify-center right-2 bg-gray-400 rounded-full"
                onClick={() => setSearchText("")}
              >
                <XIcon className="w-3 h-3 text-white" />
              </div>
            )}

            <input
              type="email"
              name="email"
              id="email"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="block w-full rounded-lg border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2 bg-gray-50"
              placeholder="Search"
            />
          </div>
        </div>
      )}

      {showDocumentList && <AnimateSharedLayout>
        {data
          .filter((chapter) => chapter.refdocs.length)
          .map((chapter) => (
            <RefLibraryItem
              refdocCount={props.refdocCount}
              firstInitiativename={showInlineAssistant ? "JHGSAOLK" : firstInitiativename}
              // searchText={searchText}
              key={chapter.id}
              chapter={chapter}
            />
          ))}
      </AnimateSharedLayout>}
      
      <br />
    </div>
  );
};
